import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import NewsLetterHomePage from '../components/NewsLetter/NewsLetterHomePage'
import Login from '../pages/login';
import '../assets/main.css';

const NewsLetter = ({ location }) => {
  

  const [agreed, setAgreed] = useState(false)

  // This runs when the page is loaded.
  useEffect(() => {
    if (localStorage.getItem('isAuthenticated')) {
      setAgreed(true)
    }
  }, [])
  return (
    <Layout location={location} crumbLabel="Newsletter">
      {!agreed ? (
        <>
        <Login loginModal={agreed} />
        <div class="card">
                 <div class="center">
               <p style={{paddingTop:"50px",paddingBottom:"50px",fontSize:"25px"}}>Only members can view this page</p>
               </div>
               </div>
        </>
      ) : (
        <div className="box ">
          <NewsLetterHomePage />
        </div>
      )}
    </Layout>
  )
}

export default NewsLetter
