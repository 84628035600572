import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import '../board.css'
import { BASEURL } from '../../utils/constants'

export const query = graphql`
  {
    allStrapiNewsletteruploads {
      nodes {
        newsletter_title
        title_color {
          color
        }
        title_size {
          size
        }
        title_font_family {
          family
        }
        title_weight {
          weight
        }
        NewsletterFiles {
          bg_color {
            color
          }
          font_color {
            color
          }
          font_size {
            size
          }
          font_weight {
            weight
          }
          font_family {
            family
          }
          newsletter_heading
          titile_align
          id
          newsletter_data {
            file_name
            file_order
            file_type
            file {
              name
              publicURL
            }
          }
        }
      }
    }
  }
`

const NewsLetterHomePage = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiNewsletteruploads: { nodes: files },
  } = data
  // debugger
  const baseURL = BASEURL
  const newsletterData = files[0]
  console.log(newsletterData)
  return (
    <div className="box center card newsletter_card">
      <h2
        className="heading"
        style={{
          color: newsletterData.title_color.color,
          fontSize: newsletterData.title_size.size,
          fontWeight: newsletterData.title_weight.weight,
          fontFamily: newsletterData.title_font_family.family,
        }}
      >
        {newsletterData.newsletter_title}
      </h2>
      <div className="box center">
        <div className="container">
          <div className="row">
            {newsletterData.NewsletterFiles.map((news, index) => (
              <div className="col-md-6" key={index}>
                <div
                  className="team-player"
                  style={{
                    height: '250px',
                    backgroundColor: news.bg_color.color,
                  }}
                >
                  <h3
                    style={{
                      color: newsletterData.title_color.color,
                      fontSize: newsletterData.title_size.size,
                      textAlign: news.titile_align,
                      fontFamily: news.font_family.family,
                    }}
                  >
                    {news.newsletter_heading}
                  </h3>

                  <ul className="ListStyle">
                    {news.newsletter_data.map((desc, index) => (
                      <Link
                        to={desc.file ? baseURL + desc.file.publicURL : ''}
                        key={index}
                        target="_blank"
                      >
                        <li
                          style={{
                            color: news.font_color.color,
                            fontSize: news.font_size.size,
                            fontWeight: news.font_weight.weight,
                            fontFamily: news.font_family.family,
                          }}
                          className="liItem"
                          key={index}
                        >
                          <p> {desc.file_name}</p>
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsLetterHomePage
